import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {PrivilegeType} from 'src/app/shared/models/privilege-type';
import {
  DialogConfiguration,
  DialogService,
  LibBaseComponent,
  Organisation,
  OrganisationClient,
  PrivilegeService,
  SnackBarService,
  SupportedDataMaster,
} from '@sesame/sesame-fe-library';
import {SmartschoolIntegrationDialogComponent} from 'src/app/shared/module/organisation-detail/smartschool-integration-dialog/smartschool-integration-dialog.component';
import {OrganisationQuery} from 'src/app/shared/service/organisation-query';
import {catchError, filter, map, switchMap} from 'rxjs/operators';
import {Error} from 'src/app/shared/service/error-handler.service';
import {EMPTY, Observable, of, ReplaySubject, Subject, throwError} from 'rxjs';
import {environment} from 'src/environments/environment';
import {OrganisationClientCommand} from 'src/app/shared/service/organisation-client-command';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-organisation-integration',
  templateUrl: './organisation-integration.component.html',
  styleUrls: ['./organisation-integration.component.scss'],
})
export class OrganisationIntegrationComponent extends LibBaseComponent implements OnInit, OnChanges, OnDestroy {
  organisationClient: OrganisationClient;
  @Input() organisation: Organisation;
  readonly PRIVILEGE_TYPE = PrivilegeType;
  isCustomerServiceContext = environment.isCustomerServiceContext;
  supportedDataMaster = SupportedDataMaster;
  protected readonly PrivilegeType = PrivilegeType;
  private onIntegrationDialogClose: Subject<OrganisationClient> = new ReplaySubject<OrganisationClient>(1);

  constructor(
    private readonly organisationQuery: OrganisationQuery,
    private readonly dialogService: DialogService,
    private readonly privilegeService: PrivilegeService,
    private readonly organisationClientCommand: OrganisationClientCommand,
    private readonly snackBarService: SnackBarService,
    private readonly translateService: TranslateService,
    private readonly router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToIntegrationDialogClose();
  }

  ngOnChanges(): void {
    this.subscribeToOrganisationClient();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  openIntegrationDialog(): void {
    const modalConfiguration = DialogConfiguration.default<OrganisationClient, OrganisationClient>(
      SmartschoolIntegrationDialogComponent,
      this.onIntegrationDialogClose
    );
    modalConfiguration
      .withReadonly()
      .withPayload(this.organisationClient)
      .withTitle(this.translateService.get('organisation.integration.dialog.title'))
      .withRightTopCornerCloseButton()
      .withSmallSize();
    modalConfiguration
      .withAbortButton(this.translateService.instant('button.cancel'))
      .withoutAbortButtonIcon()
      .withSubmitButton(this.translateService.instant('button.save'));
    this.dialogService.open(modalConfiguration);
  }

  showSmsDataImport(): void {
    this.router.navigate([`organisations/${this.organisation.organisationId}/sms-data-import`]);
  }

  private subscribeToIntegrationDialogClose(): void {
    this.onIntegrationDialogClose
      .pipe(
        this.takeUntilDestroy(),
        switchMap((organisationClient) => this.updateOrganisationClient(organisationClient))
      )
      .subscribe((result) => {
        if (result) {
          this.organisationClient = result;
          this.organisation.dataMaster = result.name;
        }
      });
  }

  private subscribeToOrganisationClient(): void {
    this.privilegeService
      .isEnabled$(PrivilegeType.MANAGE_ORGANISATION_CLIENT)
      .pipe(
        this.takeOne(),
        filter((isEnabled) => !!isEnabled),
        map(() => this.organisation),
        filter((organisation) => (Object.values(SupportedDataMaster) as any[]).includes(organisation.dataMaster)),
        switchMap(() => this.organisationQuery.findOrganisationClient(this.organisation)),
        catchError((error: Error) => {
          this.organisationClient = null;
          if (error.error.status === 404) {
            return EMPTY;
          } else {
            return throwError(() => error);
          }
        })
      )
      .subscribe((organisationClient) => {
        this.organisationClient = organisationClient;
      });
  }

  private updateOrganisationClient(organisationClient: OrganisationClient): Observable<OrganisationClient> {
    return this.organisationClientCommand.updateClient(this.organisation.organisationId, organisationClient).pipe(
      this.takeOne(),
      switchMap(() => of(organisationClient)),
      catchError((error: Error) => {
        this.snackBarService.showError({title: error.error.message});
        return throwError(() => error);
      })
    );
  }
}
