import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AuthService} from '@auth0/auth0-angular';
import {environment} from 'src/environments/environment';
import {
  AvatarProps,
  CurrentUserService,
  ENGLISH,
  LibBaseComponent,
  LibLanguageService,
  SnackBarService
} from '@sesame/sesame-fe-library';
import {Auth0ErrorInterpreter} from 'src/app/core/components/main-toolbar/auth0-error-interpreter';
import {TableDataStorageService} from 'src/app/shared/service/table-data-storage.service';
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-main-toolbar',
  templateUrl: './main-toolbar.component.html',
  styleUrls: ['./main-toolbar.component.scss']
})
export class MainToolbarComponent extends LibBaseComponent implements OnInit {

  @Output() sidenavToggle = new EventEmitter<void>();
  errorOccurred = false;
  readonly appTitle = environment.app.name;
  avatarProps: AvatarProps;
  isCustomerServiceContext = environment.isCustomerServiceContext;

  constructor(
    public readonly authService: AuthService,
    public readonly languageService: LibLanguageService,
    private readonly snackBarService: SnackBarService,
    private readonly router: Router,
    private readonly translateService: TranslateService,
    private readonly currentUserService: CurrentUserService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeOnErrorWhenLogging();
    this.currentUserService.currentUserData.pipe(
      this.takeUntilDestroy()
    ).subscribe((user) => {
      this.avatarProps = {
        picture: user.picture,
        username: user.username,
        careers: user.roles ? user.roles : undefined,
        email: user.email
      }
    });
  }

  onLoginClick(language: string = ENGLISH.code): void {
    this.authService.loginWithRedirect({
      authorizationParams: {language: language}
    });
  }

  onLogoutClick(): void {
    TableDataStorageService.clearData();
    this.authService.logout({
      logoutParams: {
        returnTo: environment.auth0.config.authorizationParams.redirect_uri,
        federated: true
      }
    });
  }

  onAccountSettingsClick(): void {
    this.router.navigate(['account-settings']);
  }

  private subscribeOnErrorWhenLogging(): void {
    this.authService.error$
      .pipe(
        this.takeUntilDestroy(),
        filter(error => error.message !== 'Login required')
      )
      .subscribe((value: Error) => {
        const messageCode = Auth0ErrorInterpreter.getMessageCode(value.message);
        const translatedMessage = this.translateService.instant(messageCode);

        if (environment.isCustomerServiceContext) {
          this.snackBarService.showInfiniteError({title: translatedMessage});
          this.errorOccurred = true;
        } else {
          this.router.navigate(['/result'],
            {queryParams: {success: false, source: 'login', errorCode: messageCode, message: translatedMessage}});
        }
      });
  }
}
