import {BehaviorSubject, distinct, Observable, Subject} from 'rxjs';
import {AuthService} from '@auth0/auth0-angular';
import {filter, map, takeUntil} from 'rxjs/operators';
import {CspBackendQuery} from 'src/app/shared/service/csp-backend.query';
import {DUTCH, ENGLISH, FRENCH, ITALY, Language, LibLanguageService, SWEDISH} from "@sesame/sesame-fe-library";
import {Injectable, OnDestroy} from '@angular/core';
import {environment} from "src/environments/environment";

@Injectable()
export class OssLanguageService implements LibLanguageService, OnDestroy {
  public static readonly OSS_PREFERRED_LANGUAGE_CODE = 'oss-prefered-language-code';
  private readonly destroyed$: Subject<boolean> = new Subject();
  private readonly allSupported = [
    ENGLISH,
    FRENCH,
    DUTCH,
    SWEDISH,
    ITALY
  ];
  private readonly currentLanguage: Subject<Language> = new BehaviorSubject<Language>(new Language(environment.defaultLang.code, environment.defaultLang.translationKey));
  private readonly languages: Subject<Language[]> = new BehaviorSubject<Language[]>(this.allSupported);
  private isAuthenticated = false;

  constructor(private authService: AuthService,
              private cspBackendQuery: CspBackendQuery) {
    this.tryToSetFromLocalStorage();
    this.authService.user$
      .pipe(
        takeUntil(this.destroyed$),
        distinct(),
        filter(user => user != null),
      )
      .subscribe(() => this.onUserChange());
  }

  private onUserChange(): void {
    this.isAuthenticated = true;
    const preferred = this.getLanguageByLocalStorage();
    if (preferred != null) {
      this.change(preferred);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public getCurrent(): Observable<Language> {
    return this.currentLanguage;
  }

  public getAll(): Observable<Language[]> {
    return this.languages
      .pipe(
        map(lang => lang.filter(l => environment.lang.includes(l.code)))
      );
  }

  public change(language: Language): void {
    if (this.isAuthenticated && !environment.disablePrivileges) {
      this.cspBackendQuery.updateMyLanguage(language.code)
        .subscribe(() => {
          this.currentLanguage.next(language);
          localStorage.setItem(OssLanguageService.OSS_PREFERRED_LANGUAGE_CODE, language.code);
        });
    } else {
      localStorage.setItem(OssLanguageService.OSS_PREFERRED_LANGUAGE_CODE, language.code);
      this.currentLanguage.next(language);
    }
  }

  private tryToSetFromLocalStorage(): void {
    const preferred = this.getLanguageByLocalStorage();
    if (preferred != null) {
      this.currentLanguage.next(preferred);
    }
  }

  private getLanguageByLocalStorage(): Language {
    const preferredFromLocalStorage = localStorage.getItem(OssLanguageService.OSS_PREFERRED_LANGUAGE_CODE);
    return this.allSupported
      .filter(l => environment.lang.includes(l.code))
      .filter(supportedLanguage => supportedLanguage.code === preferredFromLocalStorage)?.[0];
  }
}
