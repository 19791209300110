<h5 class="subtitle">
  {{ 'organisation.user.change-organisation-dialog.subtitle' | translate: {organisationName: payload.organisation.name}  }}
</h5>

<mat-vertical-stepper #stepper [disableRipple]="true" linear
                      id="dialogContent">
  <mat-step #signOutFromCurrentPlatformStep [editable]="false" [completed]="signOutFromCurrentPlatformStep.completed">
    <ng-template matStepLabel>
      <app-conditional-label
              [condition]="stepper.selectedIndex>0"
              default="{{ 'organisation.user.change-organisation-dialog.step1.title' | translate }}"
              value="{{'organisation.user.change-organisation-dialog.step1.completed' | translate:
              {dataMasterSystem: payload.organisation.dataMaster + ' ' + (payload.organisation.dataMasterType ? '(' + payload.organisation.dataMasterType + ')' : '')} }}">
      </app-conditional-label>
    </ng-template>
    <lib-inline-message [style]="InlineMessageStyle.ERROR" *ngIf="showSwitchOffErrorMessage; else platformUserPanel">
      <span>
        {{ 'organisation.user.change-organisation-dialog.unlink-failed' | translate }}
     </span>
    </lib-inline-message>

    <ng-template #platformUserPanel>
      <app-organisation-platform-user-panel
              (statusChange)="onIsPlatformUserFormChange($event)"
              [organisation]="payload.organisation">
      </app-organisation-platform-user-panel>
    </ng-template>

    <lib-inline-message [style]="InlineMessageStyle.WARN" [inactive]="showSwitchOffErrorMessage">
      <span>
        {{ 'organisation.user.change-organisation-dialog.unlink-warning' | translate }}
      </span>
    </lib-inline-message>

    <section class="actions">
      <button color="primary" mat-stroked-button (click)="abort()">
        <span>{{ 'button.cancel' | translate }}</span>
      </button>

      <button color="primary" [disabled]="!signOutFromCurrentPlatformStep.completed || showSwitchOffErrorMessage"
              mat-flat-button
              (click)="signOutFromSms()">
        <span>{{ 'button.nextStep' | translate }}</span>
      </button>
    </section>
  </mat-step>
  <mat-step [editable]="true" [completed]="selectedDataMasterSystemStep.completed" #selectedDataMasterSystemStep>
    <ng-template matStepLabel>
      <app-conditional-label
              [condition]="stepper.selectedIndex>1"
              default="{{'organisation.user.change-organisation-dialog.step2.title' | translate }}"
              value="{{selectedDataMasterSystem}}">
      </app-conditional-label>
    </ng-template>

    <app-select-data-master-system-panel
            [organisation]="payload.organisation"
            (dataMasterSelected)="onDataMasterSelected($event)">
    </app-select-data-master-system-panel>

    <section class="actions">
      <button color="primary" mat-stroked-button (click)="abort()">
        <span>{{ 'button.cancel' | translate }}</span>
      </button>
      <button mat-flat-button (click)="nextStep()" color="primary" [disabled]="!selectedDataMasterSystem">
        <span>{{ 'button.nextStep' | translate }}</span>
      </button>
    </section>
  </mat-step>
  <mat-step [editable]="true" [completed]="false">
    <ng-template matStepLabel>
      {{ 'organisation.user.change-organisation-dialog.step3.title' | translate }}
    </ng-template>
    <app-select-linked-organisation-panel class="w-100" [organisation]="payload.organisation" (organisationsSelected)="onOrganisationSelected($event)">
    </app-select-linked-organisation-panel>
  </mat-step>
</mat-vertical-stepper>

