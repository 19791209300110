import {Component} from '@angular/core';
import {
  Language,
  LibBaseComponent,
  LibLanguageService,
  LibOrganisationContextService,
  MyOrganisation
} from '@sesame/sesame-fe-library';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Observable, of, switchMap} from 'rxjs';
import {AuthService} from '@auth0/auth0-angular';
import {Organisation} from "src/app/shared/models/organisation";
import {OrganisationQuery} from "src/app/shared/service/organisation-query";
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-organisation-support-content-page',
  templateUrl: './organisation-support-content-page.component.html',
  styleUrls: ['./organisation-support-content-page.component.scss'],
})
export class OrganisationSupportContentPageComponent extends LibBaseComponent {
  readonly allLanguages: Observable<Language[]>;
  readonly currentLanguage: Observable<Language>;
  readonly allOrganisations: Observable<MyOrganisation[]>;
  readonly currentOrganisationContext: Observable<MyOrganisation>;
  readonly organisation: Observable<Organisation>;
  isAuthenticated = false;
  isSmsDataImportVisible = false;
  showOrganisationDetails = false;

  constructor(private readonly organisationContextService: LibOrganisationContextService,
              private readonly languageService: LibLanguageService,
              private readonly router: Router,
              private readonly activatedRoute: ActivatedRoute,
              private readonly authService: AuthService,
              private readonly organisationQuery: OrganisationQuery) {
    super();
    authService.isAuthenticated$
      .pipe(this.takeUntilDestroy())
      .subscribe(isAuthenticated => this.onAuthenticationChanged(isAuthenticated));
    this.allLanguages = languageService.getAll();
    this.currentLanguage = languageService.getCurrent();
    this.allOrganisations = organisationContextService.getAll();
    this.currentOrganisationContext = organisationContextService.getCurrent();
    this.organisation = this.currentOrganisationContext.pipe(
      switchMap(myOrganisation => myOrganisation ? this.organisationQuery.findOne(myOrganisation.organisationId) : of(null))
    );
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
        this.isSmsDataImportVisible = event.url.endsWith('/sms-data-import');

        var href = event.url;
        href = href.replace(/\?.*/, '');
        this.showOrganisationDetails = !href.endsWith('/user-details');
      }
    );

    this.changeOrganisationIfProvidedInQueryParam();
  }

  compareLanguagesByCode(obj1: Language, obj2: Language): boolean {
    return obj1.code === obj2.code;
  }

  changeCurrentOrganisationContext($event: MyOrganisation): void {
    this.organisationContextService.change($event);
  }

  changeCurrentLanguage($event: Language): void {
    this.languageService.change($event);
  }

  private onAuthenticationChanged(isAuthenticated): void {
    this.isAuthenticated = isAuthenticated;
  }

  private changeOrganisationIfProvidedInQueryParam(): void {
    this.organisationContextService.getAll().pipe(
      this.takeUntilDestroy(),
    ).subscribe(response => {
      const organisationId = this.activatedRoute.snapshot.queryParamMap.get('organisationId');
      const foundOrganisation = response.find(organisation => organisation.organisationId === organisationId);

      if (foundOrganisation) {
        this.organisationContextService.change(foundOrganisation)
      }
    })
  }
}
