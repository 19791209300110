import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CspBackendHttpClient} from '@sesame/sesame-fe-library';
import {UserList} from 'src/app/shared/models/user-list';
import {QueryBuilder} from 'src/app/shared/util/query-builder';
import {UpdateUserDetails, UserDetails} from 'src/app/shared/models/user';

@Injectable({
  providedIn: 'root'
})
export class CspBackendQuery {

  constructor(
    private readonly cspBackendHttpClient: CspBackendHttpClient
  ) {
  }

  getMyUserDetails(): Observable<UserDetails> {
    const reqUrl = QueryBuilder.forPath('my/user')
      .build();
    return this.cspBackendHttpClient.get<UserDetails>(reqUrl);
  }
  updateMyLanguage(language: string): Observable<void> {
    const reqUrl = QueryBuilder.forPath('my/user')
      .build();
    return this.cspBackendHttpClient.patch<void, UpdateUserDetails>(reqUrl, {language} as UpdateUserDetails);
  }
}
