<h5 class="subtitle">
  {{ 'organisation.user.change-organisation-dialog.subtitle' | translate: {organisationName: payload.organisation.name} }}
</h5>

<lib-inline-message [style]="InlineMessageStyle.WARN">
  <span>{{ 'organisation.user.delete-organisation-data-dialog.delete-warning' | translate }}</span>
</lib-inline-message>

<div class="note">{{ 'organisation.user.delete-organisation-data-dialog.note' | translate }}</div>
<div class="question">{{ 'organisation.user.delete-organisation-data-dialog.question' | translate }}</div>

