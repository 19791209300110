import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {LibBaseComponent, WisaOrganisationClient} from "@sesame/sesame-fe-library";

@Component({
  selector: 'app-wisa-integration-details',
  templateUrl: './wisa-integration-details.component.html',
  styleUrls: ['./wisa-integration-details.component.scss']
})
export class WisaIntegrationDetailsComponent extends LibBaseComponent implements OnChanges {
  @Input() organisationClient: WisaOrganisationClient;
  isClientSecretVisible: boolean;

  constructor() {
    super();
  }

  toggleClientSecretVisibility(): void {
    this.isClientSecretVisible = !this.isClientSecretVisible;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isClientSecretVisible = false;
  }
}
