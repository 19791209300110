import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  GroupMemberType,
  Organisation,
  OrganisationClient,
  OrganisationResponse,
  OrganisationsListResponse,
  OsmHttpClientService
} from '@sesame/sesame-fe-library';
import {map} from 'rxjs/operators';
import {QueryBuilder} from 'src/app/shared/util/query-builder';
import {OrganisationList} from "src/app/shared/models/organisation-person";
import {cachedObservable} from 'cached-observable';
import {AppVariables} from "src/app/shared/util/app-variables";
import {OrganisationsList, OrganisationsListCriteria} from '../models/organisation';

@Injectable({
  providedIn: 'root'
})
export class OrganisationQuery {

  constructor(private readonly http: OsmHttpClientService) {
  }

  findOne(organisationId: string): Observable<Organisation> {
    const url = QueryBuilder.forPath('organisations/{organisationId}')
      .withPathVariable('organisationId', organisationId)
      .build();
    const request = this.http.get<OrganisationResponse>(url)
      .pipe(
        map(Organisation.of)
      );
    return cachedObservable(request, url.url, AppVariables.cacheTime.organisations);
  }

  findAll(criteria: OrganisationsListCriteria): Observable<OrganisationsList> {
    const query = QueryBuilder.forPath('organisations')
      .withRequestParamIfDefined('lastIndex', criteria.lastIndex)
      .withRequestParamIfDefined('externalId', criteria.organisationId)
      .withRequestParamIfDefined('name', criteria.name)
      .withRequestParamIfDefined('city', criteria.city)
      .withRequestParamIfDefined('sector', criteria.sector)
      .withRequestParamIfDefined('dataMaster', criteria.dataMaster)
      .build();
    return this.http.get<OrganisationsListResponse>(query)
      .pipe(
        map(OrganisationsList.of)
      );
  }

  findMembers(organisationId: string, query: string, gradeLevels: string, roleName: GroupMemberType, lastIndex: number, pageSize: number, groupId: string): Observable<OrganisationList> {
    const url = QueryBuilder.forPath(`organisations/${organisationId}/members`)
      .withRequestParamIfDefined('query', query)
      .withRequestParamIfDefined('gradeLevels', gradeLevels)
      .withRequestParamIfDefined('roleName', roleName)
      .withRequestParamIfDefined('groupId', groupId)
      .withRequestParamIfDefined('lastIndex', lastIndex)
      .withRequestParamIfDefined('pageSize', pageSize)
      .build();
    return this.http.get<OrganisationList>(url);
  }

  findOrganisationClient(organisation: Organisation): Observable<OrganisationClient> {
    const url = QueryBuilder.forPath(`organisations/${organisation.organisationId}/${organisation.dataMaster.toLowerCase()}-client`)
      .withPathVariable('organisationId', organisation.organisationId)
      .build();

    return this.http.get<OrganisationClient>(url);
  }
}
