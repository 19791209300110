import {OperationalOrganisation, OrganisationResponse, OrganisationsListResponse} from "@sesame/sesame-fe-library";

export class OrganisationsList {
  constructor(public organisations: Organisation[], public pageSize: number, public lastIndex: number) {
  }

  static of(response: OrganisationsListResponse) {
    return new OrganisationsList(
      response.organisations.map(Organisation.of),
      response.pageSize,
      response.lastIndex
    );
  }
}

export class OrganisationsListFilter {
  constructor(
    public organisationId: string,
    public name: string,
    public city: string,
    public sector: string,
  ) {
  }
}

export class OrganisationsListCriteria {

  constructor(
    public lastIndex: number,
    public organisationId?: string,
    public name?: string,
    public city?: string,
    public sector?: string,
    public newFilterValue?: boolean,
  ) {
  }

  static empty(): OrganisationsListCriteria {
    return new OrganisationsListCriteria(0);
  }

}

export class Organisation {
  constructor(
    public organisationId: string,
    public externalId: string,
    public name: string,
    public name2: string,
    public city: string,
    public sector: string,
    public language: string,
    public isPlatformUser: boolean,
    public isPrimaryEducation: boolean,
    public operationalOrganisations: OperationalOrganisation[],
    public dataMaster: string,
    public dataMasterType?: DataMasterType
  ) {
  }

  static of(response: OrganisationResponse): Organisation {
    return new Organisation(
      response.organisationId,
      response.externalId,
      response.name,
      response.name2 ? response.name2 : '',
      response.city,
      response.sector,
      response.language,
      response.isPlatformUser,
      response.isPrimaryEducation,
      response.operationalOrganisations,
      response.dataMaster,
      DataMasterType[response.dataMasterType?.toUpperCase()]
    );
  }
}


export enum DataMasterType {
  EXTERNAL = 'external',
  INTERNAL = 'internal'
}

export interface OperationalOrganisationsResponse {
  pageSize: number,
  lastIndex: number,
  organisations: OperationalOrganisation[];
}

export interface OrganisationClient {
  name: string;
}

export interface WisaOrganisationClient extends OrganisationClient {
  organisationId: string,
  apiToken: string
}

export interface SmartschoolOrganisationClient extends OrganisationClient {
  clientId: string,
  clientSecret: string,
  accessUrl: string,
  tokenUrl: string,
}

export enum SupportedDataMaster {
  SMARTSCHOOL = "smartschool",
  WISA = "wisa",
  SKOLON = "skolon"
}