import {RumInitConfiguration} from "@datadog/browser-rum";
import {SupportedDataMaster} from "@sesame/sesame-fe-library";

export const SMARTSCHOOL_CONNECTION_NAME = 'Smartschool';
export const SMARTSCHOOL_CONNECTION_VALUE = 'Smartschool';
export const GOOGLE_CONNECTION_NAME = 'Google';
export const GOOGLE_CONNECTION_VALUE = 'google-oauth2';
export const MICROSOFT_CONNECTION_NAME = 'Microsoft';
export const MICROSOFT_CONNECTION_VALUE = 'Microsoft';
export const VAN_IN_CONNECTION_NAME = 'VAN IN';
export const VAN_IN_CONNECTION_VALUE = 'VanIn-Database';
export const EMPLOYEE_CONNECTION_NAME = 'Employee';
export const EMPLOYEE_CONNECTION_VALUE = 'VanIn';
export const APPLE_CONNECTION_NAME = 'Apple';
export const APPLE_CONNECTION_VALUE = 'apple';
export const SKOLON_CONNECTION_NAME = 'Skolon';
export const SKOLON_CONNECTION_VALUE = 'Skolon';
export const SKOLON_TEST_CONNECTION_NAME = 'SkolonTest';
export const SKOLON_TEST_CONNECTION_VALUE = 'SkolonTest';
export const UTBILDNING_CONNECTION_NAME = 'Utbildning';
export const UTBILDNING_CONNECTION_VALUE = 'Utbildning-Database';
export const ITALY_CONNECTION_NAME = 'Italy';
export const ITALY_CONNECTION_VALUE = 'Sanoma-Italy-Database';
export const ZAINO_CONNECTION_NAME = 'Zaino Digitale';
export const ZAINO_CONNECTION_VALUE = 'ZainoDigitale';


export interface Environemnt {
  domain: string;
  entryPoint: 'CS' | 'OSS';
  isCustomerServiceContext: boolean;
  app: App
  tenant: 'vanin' | 'utbildning' | 'italy' | 'sanoma-pro';
  tenantName: string;
  logo: Logo;
  production: boolean;
  envName: string;
  lms: ExternalService;
  osm: ExternalService;
  cspBackend: ExternalService;
  ups: ExternalService;
  ess: ExternalService;
  apd: ExternalService;
  auth0: Auth0Configuration;
  piwikPro?: PiwikProConfiguration;
  externalStorage: ExternalStorageConfiguration;
  features: Features
  disablePrivileges: boolean;
  datadog: RumInitConfiguration;
  organisationContext: OrganisationContext;
  gradeLevel: GradeLevel;
  maxBulkPasswordResetSize: number;
  defaultLang: Language;
  lang: string[];
  availableDataMaster?: SupportedDataMaster[];
}

export interface App {
  version: string;
  name: string;
}

export interface Logo {
  favIcon: string;
  full: string;
  raw: string;
  showInAccountSettings: boolean;
}

export interface ExternalService {
  baseUrl: string;
}

export interface OrganisationContext {
  allowedRoles: string[];
}

export interface GradeLevel {
  values: string;
}

export interface Language {
  code: LanguageCode;
  translationKey: string;
}

export type LanguageCode = "en_en" | "fr_be" | "nl_be" | "sv_se" | "fi_fi"

export interface FooterLogo {
  desktop: string
  mobile: string;
}

export interface Auth0Configuration {
  config: any;
}

export interface Auth0LoginOptions {
  connection?: string;
  // add additional if needed based on
}

export interface PiwikProConfiguration {
  containerId: string;
  containerUrl: string;
}

export interface ExternalStorageConfiguration {
  media: MediaConfiguration
}

export interface MediaConfiguration {
  baseUrl: string;
  filename: {
    students: string;
    studentsPE: string;
    teachers: string;
    groups: string;
  }
}

interface Connection {
  name: string;
  value: string;
}

export interface Features {
  CS: {
    groupsModule: boolean
  },
  OSS: {
    groupsModule: boolean
  },
  users: {
    connections: Connection[],
    '3rdUserAccountCreation': boolean,
    showDetailedConfirmationInfoAfterOsUserEmailEdit: boolean
  }
}