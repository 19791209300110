import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {combineLatest, Observable, of} from 'rxjs';
import {PrivilegeService} from '@sesame/sesame-fe-library';
import {SnackBarService} from '@sesame/sesame-fe-library';
import {map} from 'rxjs/operators';
import {PrivilegeType} from 'src/app/shared/models/privilege-type';

@Injectable({
  providedIn: 'root'
})
export class CanActivateRouteGuard {

  constructor(
    private privilegeService: PrivilegeService,
    private snackbarService: SnackBarService,
    private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const requiredPrivileges = route.data.requiredPrivileges as Array<PrivilegeType>;
    if (!requiredPrivileges || requiredPrivileges.length === 0) {
      return of(true);
    }
    const obs = requiredPrivileges.map(p => this.privilegeService.isEnabled$(p));
    return combineLatest(obs)
      .pipe(
        map((values: boolean[]) => {
          const atLeastOneIsFalse = values.some(val => val === false);
          if (atLeastOneIsFalse) {
            this.snackbarService.showError({title: 'You are not authorized to perform this action.'});
            return this.router.createUrlTree(['/']);
          } else {
            return true;
          }
        })
      );
  }
}
