<app-organisations-details-info-panel
  *ngIf="organisation$ | async as organisation"
  [organisation]="organisation"
  [withExpanded]="true">
</app-organisations-details-info-panel>

<mat-grid-list cols="2" rowHeight="2:1" class="w-50 ml-25p">
  <ng-container *ngFor="let item of menuItems">
    <mat-grid-tile>
      <button class="w-75 mr-4" color="primary" mat-raised-button
              [id]="item.id"
              (click)="navigate(item.path)"
              *requireAllPrivileges="item.privilegeTypes">
        <fa-icon class="mr-2" [icon]="['far', item.icon]" size="lg"></fa-icon>
        <label>{{item.label | translate}}</label>
      </button>
    </mat-grid-tile>
  </ng-container>
</mat-grid-list>
