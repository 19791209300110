import {SupportedDataMaster} from '@sesame/sesame-fe-library';
import gitVersion from 'git-version.json';
import {
  APPLE_CONNECTION_NAME,
  APPLE_CONNECTION_VALUE,
  EMPLOYEE_CONNECTION_NAME,
  EMPLOYEE_CONNECTION_VALUE,
  Environemnt,
  GOOGLE_CONNECTION_NAME,
  GOOGLE_CONNECTION_VALUE,
  MICROSOFT_CONNECTION_NAME,
  MICROSOFT_CONNECTION_VALUE,
  SMARTSCHOOL_CONNECTION_NAME,
  SMARTSCHOOL_CONNECTION_VALUE,
  VAN_IN_CONNECTION_NAME,
  VAN_IN_CONNECTION_VALUE
} from "src/environments/model";
const domain = window.location.hostname;
const entryPoint = domain === 'vanin.dev.csp.sanomalearning.com' ? 'CS' : 'OSS';
const isCustomerServiceContext = entryPoint === 'CS';
export const environment: Environemnt = {

  domain,
  entryPoint,
  isCustomerServiceContext,
  app: {
    version: gitVersion.raw,
    name: entryPoint === 'CS' ? 'Customer Service Portal' : 'portal.title',
  },
  production: false,
  envName: 'dev',
  tenantName: 'VAN IN',
  tenant: 'vanin',
  logo: {
    favIcon: './assets/img/vanin_favicon.svg',
    full: './assets/img/Vanin_full_logo.svg',
    raw: './assets/img/Vanin_raw_logo.svg',
    showInAccountSettings: true
  },
  lms: {
    baseUrl: 'https://vanin.dev.licenses.sanomalearning.com'
  },
  cspBackend: {
    baseUrl: 'https://vanin.dev.csp-backend.sanomalearning.com'
  },
  osm: {
    baseUrl: 'https://vanin.dev.osm.sanomalearning.com'
  },
  ess: {
    baseUrl: 'https://vanin.dev.search.sanomalearning.com'
  },
  apd: {
    baseUrl: 'https://vanin.dev.apd.sanomalearning.com'
  },
  ups: {
    baseUrl: 'https://vanin.dev.userprofile.sanomalearning.com'
  },
  externalStorage: {
    media: {
      baseUrl: 'https://media.sesame-prd.mbgaws.net/customer-service-portal/dev/',
      filename: {
        students: 'student+example+csv-file.csv',
        studentsPE: 'student+PE+example+csv-file.csv',
        teachers: 'teacher+example+csv-file.csv',
        groups: 'group+example+csv-file.csv'
      }
    }
  },
  auth0: {
    config: {
      clientId: entryPoint === 'CS' ? '78BzYny709iaS4yxoonYOk0lp8JApWEN' : 'AD0w87mLwiW3HqMtLMDUOzoAupIDT1dB',
      domain: 'dev.login.vanin.be',
      useRefreshTokens: true,
      authorizationParams: {
        audience: 'https://sesame.sanomalearning.com',
        redirect_uri: window.location.origin
      }
    }
  },
  disablePrivileges: true,
  datadog: null,
  features: {
    CS: {
      groupsModule: true
    },
    OSS: {
      groupsModule: true
    },
    users: {
      connections: [
        {name: APPLE_CONNECTION_NAME, value: APPLE_CONNECTION_VALUE},
        {name: MICROSOFT_CONNECTION_NAME, value: MICROSOFT_CONNECTION_VALUE},
        {name: GOOGLE_CONNECTION_NAME, value: GOOGLE_CONNECTION_VALUE},
        {name: SMARTSCHOOL_CONNECTION_NAME, value: SMARTSCHOOL_CONNECTION_VALUE},
        {name: EMPLOYEE_CONNECTION_NAME, value: EMPLOYEE_CONNECTION_VALUE},
        {name: VAN_IN_CONNECTION_NAME, value: VAN_IN_CONNECTION_VALUE},
      ],
      '3rdUserAccountCreation': false,
      showDetailedConfirmationInfoAfterOsUserEmailEdit: false
    }
  },
  organisationContext: {
    allowedRoles: ['schooladmin']
  },
  gradeLevel: {
    values: ""
  },
  maxBulkPasswordResetSize: 40,
  defaultLang: {
    code: 'nl_be',
    translationKey: 'language.dutch'
  },
  lang: ['en_en', 'nl_be', 'fr_be'],
  availableDataMaster: [SupportedDataMaster.WISA, SupportedDataMaster.SMARTSCHOOL]
};
