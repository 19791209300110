import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
  Action,
  ButtonType,
  Color,
  DialogBaseComponent,
  DialogService,
  OperationalOrganisation,
} from '@sesame/sesame-fe-library';
import {OperationalOrganisationService} from 'src/app/shared/service/operational-organisation.service';
import {
  ConfigurePlatformUserDialogData,
  OperationalOrganisationElement,
} from 'src/app/shared/module/organisation-detail/configure-platform-user-dialog/configure-platform-user-dialog-models';

@Component({
  selector: 'app-configure-platform-user-dialog',
  templateUrl: './configure-platform-user-dialog.component.html',
  styleUrls: ['./configure-platform-user-dialog.component.scss'],
})
export class ConfigurePlatformUserDialogComponent
  extends DialogBaseComponent<ConfigurePlatformUserDialogData, void>
  implements OnDestroy {
  static readonly CANCEL_ACTION: Action = {
    text: 'button.cancel',
    id: 'cancel',
    style: {color: Color.primary, buttonType: ButtonType.stroked},
    isEnabled: true,
  };
  static readonly SAVE_ACTION: Action = {
    text: 'button.save',
    id: 'save',
    style: {color: Color.primary, buttonType: ButtonType.flat},
    isEnabled: true,
  };

  readonly PAGE_SIZE = 10;

  organisations: OperationalOrganisationElement[] = [];
  selectedOrganisations: OperationalOrganisationElement[] = [];
  selectedDataMasterSystem?: string;

  firstStepIndex: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: ConfigurePlatformUserDialogData,
    private dialogRef: MatDialogRef<ConfigurePlatformUserDialogComponent>,
    private readonly updateOperationalOrganisationCommand: OperationalOrganisationService,
    dialogService: DialogService
  ) {
    super(dialogService, data);
    this.setFirstStepIndex();
    this.selectedDataMasterSystem = this.payload.organisation?.dataMaster;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  close(): void {
    this.dialogRef.close();
  }

  submit(): void {
    const selectedOperationalOrganisationIds = this.selectedOrganisations.map((organisation) => organisation.id);
    this.updateOperationalOrganisationCommand
      .updateOperationalOrganisation(
        this.payload.organisation.organisationId,
        selectedOperationalOrganisationIds,
        this.selectedDataMasterSystem
      )
      .pipe(this.takeUntilDestroy())
      .subscribe(() =>
        this.dialogRef.close({
          organisationId: this.payload.organisation.organisationId,
          operationalOrganisations: this.selectedOrganisations.map(
            (organisation) =>
              new OperationalOrganisation(
                organisation.name,
                organisation.id,
                organisation.city,
                organisation.linkedOrganisation
              )
          ),
        })
      );
  }

  getResult(): void {
  }

  onAction(action: Action) {
    if (action.id === ConfigurePlatformUserDialogComponent.SAVE_ACTION.id) {
      this.submit();
    } else if (action.id === ConfigurePlatformUserDialogComponent.CANCEL_ACTION.id) {
      this.close();
    } else {
      console.error("An error occurred in onAction");
    }
  }

  private setFirstStepIndex(): void {
    if (this.payload.editMode) {
      this.firstStepIndex = 1;
    } else {
      this.firstStepIndex = 0;
    }
  }
}
