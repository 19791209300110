import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CustomValidators} from "src/app/shared/util/custom-validators";

import {
  DialogBaseComponent,
  DialogConfiguration,
  SmartschoolOrganisationClient,
  SupportedDataMaster,
  FormUtils
} from "@sesame/sesame-fe-library";

@Component({
  selector: 'app-smartschool-integration-dialog',
  templateUrl: './smartschool-integration-dialog.component.html',
  styleUrls: ['./smartschool-integration-dialog.component.scss']
})
export class SmartschoolIntegrationDialogComponent extends DialogBaseComponent<SmartschoolOrganisationClient, SmartschoolOrganisationClient> implements OnDestroy {

  form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: {
      config: DialogConfiguration<SmartschoolOrganisationClient, SmartschoolOrganisationClient>
    },
    private readonly formBuilder: FormBuilder,
  ) {
    super(null, data);
    this.createForm();
    this.subscribeOnFormChange();
    this.setSaveButtonEnabledStatus(this.form.valid);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  getResult(): SmartschoolOrganisationClient {
    return {
      clientId: this.form.get('clientId').value,
      clientSecret: this.form.get('clientSecret').value,
      accessUrl: this.form.get('accessUrl').value,
      tokenUrl: this.form.get('tokenUrl').value,
      name: SupportedDataMaster.SMARTSCHOOL.toLowerCase()
    }
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      clientId: [this.data.config.payload?.clientId ?? null, [Validators.required]],
      clientSecret: [this.data.config.payload?.clientSecret ?? null, [Validators.required]],
      accessUrl: [this.data.config.payload?.accessUrl ?? null, [Validators.required, Validators.pattern(CustomValidators.URL_PATTERN)]],
      tokenUrl: [this.data.config.payload?.tokenUrl ?? null, [Validators.required, Validators.pattern(CustomValidators.URL_PATTERN)]],
    });
  }

  private subscribeOnFormChange(): void {
    this.form.valueChanges
      .pipe(this.takeUntilDestroy())
      .subscribe(() => {
        FormUtils.trimValueInField(this.form, 'clientId');
        FormUtils.trimValueInField(this.form, 'clientSecret');
        FormUtils.trimValueInField(this.form, 'accessUrl');
        FormUtils.trimValueInField(this.form, 'tokenUrl')
        this.setSaveButtonEnabledStatus(this.form.valid);
      });
  }

}
