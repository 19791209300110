import {Router} from '@angular/router';
import {ErrorHandler, Injectable} from '@angular/core';
import {SnackBarService} from '@sesame/sesame-fe-library';
import {environment} from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import {AppVariables} from 'src/app/shared/util/app-variables';
import {Observable, of} from "rxjs";
import {TranslateService} from "@ngx-translate/core";

export const UNEXPECTED_ERROR = 'Unexpected error occurred. Please contact with support';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {

  private readonly isProduction: boolean;

  constructor(
    private readonly snackBarService: SnackBarService,
    private readonly router: Router,
    private readonly translate: TranslateService
  ) {
    this.isProduction = environment.production;
  }

  public static handleHttpError(error: any, route: string, snackBar: SnackBarService, router: Router) {
    if (error.error instanceof HttpErrorResponse) {
      snackBar.showError({title: error.error.error.errorDetail});
    } else {
      snackBar.showError(error);
    }
    router.navigateByUrl(route);
    return of(null);
  }

  handleError(error: any): void {
    if (!this.isProduction) {
      console.error(error)
    }
    if (error.error instanceof HttpErrorResponse) {
      this.handleHttpErrorResponse(error.error);
      return;
    }

    if (error instanceof Error && !this.isProduction) {
      this.handleApplicationError(error);
    } else if (error.message) {
      this.snackBarService.showError({title: error.message});
    } else {
      this.snackBarService.showError({title: UNEXPECTED_ERROR});
    }
  }

  public navigateOnError(error: any, route: string, errorMessageTranslationKey: string): Observable<any> {
    this.snackBarService.showError(this.translate.instant(errorMessageTranslationKey));
    this.router.navigateByUrl(route);
    return of(null);
  }

  private handleApplicationError(error: Error): void {
    this.snackBarService.showError({title: error.error.message});
  }

  private handleHttpErrorResponse(error: HttpErrorResponse): void {
    if (!this.handleHttpError(error)) {
      this.handleCustomError(error)
    }
  }

  private handleCustomError(error: HttpErrorResponse): void {
    const errorCode = error?.error?.errorCode;

    switch (errorCode) {
      case ErrorCodes.ASSIGNEE_ALREADY_EXISTS_ON_LICENSE:
        this.snackBarService.showError({title: 'Specified assignee already exists for this license'});
        break;
      case ErrorCodes.ACTIVATION_CODES_NUMBER_EXCEEDED:
        this.snackBarService.showError({
          title: `The number of new codes would exceed the total number of possible physical codes (${AppVariables.licenseActivationCodes.maxQuantity}).`
        });
        break;
      case ErrorCodes.CAN_NOT_FIND_ACTIVATION_CODE:
        this.snackBarService.showError({title: 'Activation code not found'});
        break;
      case ErrorCodes.CSP_B_CAN_NOT_EMAIL_ALREADY_EXISTS:
        this.snackBarService.showError({title: 'The specified new email already exists'});
        break;
      case ErrorCodes.ERROR_ON_3RD_ACCOUNT_CREATION:
        this.handle3rdAccountCreationError(error);
        break;
      case ErrorCodes.CAN_NOT_LINK_SIMPLIFIED_ACCOUNT_TO_NON_STUDENT:
        this.snackBarService.showError({
          title: this.translate.instant('user.identities.linking.error.can-not-link-simplified-account-to-student')
        });
        break;
      case ErrorCodes.EDIT_USER_EMAIL_IN_USE:
      case ErrorCodes.EDIT_USER_EMAIL_REQUIRED:
      case ErrorCodes.EDIT_EASY_LOGIN_EMAIL_IN_USE:
      case ErrorCodes.WRONG_USERNAME_LENGTH:
      case ErrorCodes.USERNAME_NOT_UNIQUE:
      case ErrorCodes.CAN_NOT_GENERATE_USERNAME:
      case ErrorCodes.USERNAME_CONTAINS_SPECIAL_CHARACTERS:
        this.snackBarService.showError({title: this.translate.instant(`error.${errorCode}`)});
        break;
      default:
        this.snackBarService.showError({title: 'An unexpected error occurred'});
        break;
    }
  }

  private handleHttpError(error: HttpErrorResponse): boolean {
    if (error.status === 403) {
      this.snackBarService.showError({title: 'You are not authorized to perform this action.'});
      return true;
    } else if (error.status === 400) {
      this.snackBarService.showError({
        title: `${UNEXPECTED_ERROR} - ${error.error.errorDetail ? error.error.errorDetail : ''}`
      });
      return true;
    } else if (error.status === 500) {
      this.snackBarService.showError({title: UNEXPECTED_ERROR});
      return true;
    } else if (error.status === 0) {
      this.snackBarService.showError({title: "Service is unreachable"});
      return true;
    }
    return false;
  }

  private handle3rdAccountCreationError(error: HttpErrorResponse): void {
    const err = error?.error?.errors;
    Object.entries(err).forEach(([key, value]) => {
      this.snackBarService.showError({title: this.translate.instant(`user.3_rd_account_creation.error.${key}`)});
    });
  }
}

export class Error {
  constructor(public error: any, public message?: string) {
  }
}

export enum ErrorCodes {
  ASSIGNEE_ALREADY_EXISTS_ON_LICENSE = 'LMS-02012',
  ACTIVATION_CODES_NUMBER_EXCEEDED = 'LMS-02015',
  CAN_NOT_FIND_ACTIVATION_CODE = 'LMS-01002',
  CSP_B_CAN_NOT_MERGE_ACCOUNTS = 'CSPB-02008',
  CSP_B_CAN_NOT_EMAIL_ALREADY_EXISTS = 'CSPB-02005',
  CAN_NOT_MERGE_ACCOUNTS = 'OSM-02030',
  CANNOT_MERGE_ACCOUNTS_LICENSES_EXISTS = 'OSM-02028',
  CANNOT_MERGE_ACCOUNTS_CAREERS_DUPLICATION = 'OSM-02029',
  WRONG_USERNAME_LENGTH = 'OSM-02033',
  USERNAME_NOT_UNIQUE = 'OSM-02034',
  CAN_NOT_GENERATE_USERNAME = 'OSM-02035',
  ERROR_ON_3RD_ACCOUNT_CREATION = 'OSM-02036',
  USERNAME_CONTAINS_SPECIAL_CHARACTERS = 'OSM-02037',
  EDIT_USER_EMAIL_IN_USE = 'OSM-02039',
  EDIT_EASY_LOGIN_EMAIL_IN_USE = 'OSM-02040',
  EDIT_USER_EMAIL_REQUIRED = 'OSM-02041',
  CAN_NOT_LINK_SIMPLIFIED_ACCOUNT_TO_NON_STUDENT = 'OSM-02011',
  CAN_NOT_MERGE_ACCOUNTS_NLID_NOT_SET_ON_PRIMARY = 'OSM-02049',
  CAN_NOT_MERGE_ACCOUNTS_NLID_NOT_MATCH = 'OSM-02050'
}

