import {Organisation} from 'src/app/shared/models/organisation';
import {Component, Inject, OnInit} from '@angular/core';
import {
  Action,
  ButtonType,
  Color,
  DialogBaseComponent,
  DialogService,
  InlineMessageStyle,
  SnackBarService,
} from '@sesame/sesame-fe-library';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {OperationalOrganisationService} from 'src/app/shared/service/operational-organisation.service';
import {catchError} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-delete-data-school-data-master-system-dialog',
  templateUrl: './delete-data-school-data-master-system-dialog.component.html',
  styleUrls: ['./delete-data-school-data-master-system-dialog.component.scss'],
})
export class DeleteDataSchoolDataMasterSystemDialogComponent
  extends DialogBaseComponent<DeleteDataSchoolDataMasterSystemDialogData, void>
  implements OnInit {
  static readonly CANCEL_ACTION: Action = {
    text: 'button.cancel',
    id: 'cancel',
    style: {color: Color.primary, buttonType: ButtonType.stroked},
    isEnabled: true,
  };
  static readonly DELETE_ACTION: Action = {
    text: 'button.delete',
    id: 'delete',
    style: {color: Color.primary, buttonType: ButtonType.flat},
    isEnabled: true,
  };
  deleteDataButtonLoading: boolean = false;
  protected readonly InlineMessageStyle = InlineMessageStyle;

  constructor(
    dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) data: DeleteDataSchoolDataMasterSystemDialogData,
    private readonly operationalOrganisationService: OperationalOrganisationService,
    private snackbarService: SnackBarService,
    private readonly router: Router,
    private readonly translate: TranslateService
  ) {
    super(dialogService, data);
  }

  ngOnInit(): void {
    this.subscribeOnDeleteDataButtonLoading();
  }

  onDeleteData() {
    this.setSaveButtonLoadingStatus(true);
    this.operationalOrganisationService
      .deleteDataSchoolManagementSystem(this.payload.organisation.organisationId)
      .pipe(
        this.takeOne(),
        catchError(() => {
          this.setSaveButtonLoadingStatus(false);
          this.snackbarService.showError({
            title: this.translate.instant('organisation.user.delete-organisation-data-dialog.error'),
          });
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.setSaveButtonLoadingStatus(false);
        this.snackbarService.showSuccess({
          title: this.translate.instant('organisation.user.delete-organisation-data-dialog.success'),
        });
        this.submit();
      });
  }

  getResult(): void {
  }

  onAction(action: Action) {
    if (action.id === DeleteDataSchoolDataMasterSystemDialogComponent.DELETE_ACTION.id) {
      this.onDeleteData();
    } else if (action.id === DeleteDataSchoolDataMasterSystemDialogComponent.CANCEL_ACTION.id) {
      this.abort();
    } else {
      console.error("An error occurred in onAction");
    }
  }

  private subscribeOnDeleteDataButtonLoading(): void {
    this.isSaveButtonLoading()
      .pipe(this.takeUntilDestroy())
      .subscribe((value: boolean) => {
        this.deleteDataButtonLoading = value;
      });
  }
}

export interface DeleteDataSchoolDataMasterSystemDialogData {
  organisation: Organisation;
}
