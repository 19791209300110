import {Injectable} from '@angular/core';
import {EMPTY, Observable} from "rxjs";
import {
  CodeDetails,
  LibLinkToOrganisationResponse,
  LinkedUserResponse,
  LinkingService,
  OsmHttpClientService
} from '@sesame/sesame-fe-library';
import {QueryBuilder} from "src/app/shared/util/query-builder";
import {LinkAnywayFormData} from "@sesame/sesame-fe-library/lib/schools/model/linking";

export interface CSPLinkToOrganisationResponse extends LibLinkToOrganisationResponse {
  organisationId: string;
}

@Injectable({
  providedIn: 'root'
})
export class OsmLinkingService implements LinkingService {

  constructor(private readonly http: OsmHttpClientService) {
  }

  getUserLinkedToCareer(ciamId: string, careerId: string): Observable<LinkedUserResponse> {
    return EMPTY;
  }

  getCodeDetails(code: string): Observable<CodeDetails> {
    return EMPTY;
  }

  linkCurrentUserToCareer(careerId: string): Observable<CSPLinkToOrganisationResponse> {
    return EMPTY;
  }


  linkUsingCode(code: string): Observable<CSPLinkToOrganisationResponse> {
    const url = QueryBuilder.forPath(`my/role-organisation-codes/${code}/activate`).build();
    return this.http.put(url, {});
  }

  requestForCode(deliveryAddress: string): Observable<void> {
    return EMPTY;
  }

  forceActivateCode(code: string, linkAnyway: LinkAnywayFormData): Observable<CSPLinkToOrganisationResponse> {
    return EMPTY;
  }
}
