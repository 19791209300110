import {Component, Inject, ViewChild} from '@angular/core';
import {
  Action,
  ButtonType,
  Color,
  DialogBaseComponent,
  DialogService,
  InlineMessageStyle,
} from '@sesame/sesame-fe-library';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Organisation} from 'src/app/shared/models/organisation';
import {MatStep, MatStepper} from '@angular/material/stepper';
import {OperationalOrganisationService} from 'src/app/shared/service/operational-organisation.service';
import {OperationalOrganisationElement} from 'src/app/shared/module/organisation-detail/configure-platform-user-dialog/configure-platform-user-dialog-models';
import {catchError} from 'rxjs/operators';
import {EMPTY} from 'rxjs';

@Component({
  selector: 'app-change-school-data-master-system',
  templateUrl: './change-school-data-master-system-dialog.component.html',
  styleUrls: ['./change-school-data-master-system-dialog.component.scss'],
})
export class ChangeSchoolDataMasterSystemDialogComponent extends DialogBaseComponent<
  ChangeSchoolDataMasterSystemDialogData,
  ChangeSchoolDataMasterSystemDialogResult
> {
  static readonly CANCEL_ACTION: Action = {
    text: 'button.cancel',
    id: 'cancel',
    style: {color: Color.primary, buttonType: ButtonType.stroked},
    isEnabled: true,
  };
  static readonly SAVE_ACTION: Action = {
    text: 'button.save',
    id: 'save',
    style: {color: Color.primary, buttonType: ButtonType.flat},
    isEnabled: false,
  };

  readonly InlineMessageStyle = InlineMessageStyle;
  @ViewChild('stepper')
  stepper: MatStepper;
  @ViewChild('signOutFromCurrentPlatformStep')
  disableCurrentPlatformStep: MatStep;
  @ViewChild('selectedDataMasterSystemStep')
  selectedDataMasterSystemStep: MatStep;
  showSwitchOffErrorMessage = false;
  selectedDataMasterSystem: string;
  selectedOrganisations: OperationalOrganisationElement[] = [];
  private status: ChangeSchoolDataMasterSystemStatus;

  constructor(
    dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) data: ChangeSchoolDataMasterSystemDialogData,
    private readonly operationalOrganisationService: OperationalOrganisationService
  ) {
    super(dialogService, data);
  }

  getResult(): ChangeSchoolDataMasterSystemDialogResult {
    return {status: this.status};
  }

  public onIsPlatformUserFormChange(isPlatformUserField: boolean) {
    this.disableCurrentPlatformStep.completed = !isPlatformUserField;
  }

  signOutFromSms() {
    this.operationalOrganisationService
      .signOutFromSchoolManagementSystem(this.payload.organisation.organisationId)
      .pipe(
        this.takeOne(),
        catchError(() => {
          this.showSwitchOffErrorMessage = true;
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.status = ChangeSchoolDataMasterSystemStatus.SIGNED_OUT;
        this.stepper.next();
      });
  }

  submit(): void {
    const selectedOperationalOrganisationIds = this.selectedOrganisations.map((organisation) => organisation.id);
    this.operationalOrganisationService
      .updateOperationalOrganisation(
        this.payload.organisation.organisationId,
        selectedOperationalOrganisationIds,
        this.selectedDataMasterSystem
      )
      .pipe(this.takeUntilDestroy())
      .subscribe(() => {
        this.status = ChangeSchoolDataMasterSystemStatus.CHANGED;
        super.submit();
      });
  }

  onOrganisationSelected(selectedOrganisation: OperationalOrganisationElement[]): void {
    this.selectedOrganisations = selectedOrganisation;
  }

  onAction(action: Action) {
    if (action.id === ChangeSchoolDataMasterSystemDialogComponent.SAVE_ACTION.id) {
      this.submit();
    } else if (ChangeSchoolDataMasterSystemDialogComponent.CANCEL_ACTION.id) {
      this.abort();
    } else {
      console.error("An error occurred in onAction");
    }
  }

  nextStep() {
    this.stepper.next();
    this.changeEnabledSaveButton();
  }

  onDataMasterSelected(dataMaster: string) {
    this.selectedDataMasterSystem = dataMaster;
    this.selectedDataMasterSystemStep.completed = !!dataMaster;
  }

  changeEnabledSaveButton() {
    this.disableCurrentPlatformStep?.completed &&
    this.selectedDataMasterSystem &&
    this.selectedDataMasterSystemStep?.completed
      ? (ChangeSchoolDataMasterSystemDialogComponent.SAVE_ACTION.isEnabled = true)
      : (ChangeSchoolDataMasterSystemDialogComponent.SAVE_ACTION.isEnabled = false);
  }
}

export interface ChangeSchoolDataMasterSystemDialogData {
  organisation: Organisation;
}

export interface ChangeSchoolDataMasterSystemDialogResult {
  status: ChangeSchoolDataMasterSystemStatus;
}

export enum ChangeSchoolDataMasterSystemStatus {
  CANCELED,
  SIGNED_OUT,
  CHANGED,
}
