import {NavigationItem} from 'src/app/shared/models/navigation-item';
import {PrivilegeType} from 'src/app/shared/models/privilege-type';

export const AppVariables = {
  enterKeyHitThrottleTime: 1000,
  cacheTime: {
    organisations: 15000 // 15 seconds
  },
  licenses: {
    maxQuantity: 10000,
  },
  licenseActivationCodes: {
    maxQuantity: 25000,
  },
  navigationItems: {
    CS: [
      new NavigationItem('home', 'house', 'menu.home', '/app', []),
      new NavigationItem('licenses-btn', 'puzzle-piece', 'menu.licenses', '/licenses', [PrivilegeType.VIEW_LICENSES]),
      new NavigationItem('users-btn', 'user-tie', 'menu.users', '/users', [PrivilegeType.VIEW_USERS]),
      new NavigationItem('activation-codes-btn', 'barcode-read', 'menu.activationCodes', '/activation-codes', [
        PrivilegeType.VIEW_LICENSE_CODES,
      ]),
      new NavigationItem('organisations-btn', 'building-columns', 'menu.organisations', '/organisations', [
        PrivilegeType.VIEW_ORGANISATIONS,
      ]),
      new NavigationItem('auto-delivery-btn', 'gear', 'menu.autoDelivery', '/auto-delivery', [
        PrivilegeType.VIEW_DELIVERY_RESULTS,
      ]),
    ],
    OSS: [
      new NavigationItem('home', 'house', 'menu.home', '/app', []),
      new NavigationItem('licenses-btn', 'puzzle-piece', 'menu.licenses', '/licenses', [PrivilegeType.VIEW_LICENSES]),
      new NavigationItem('groups-btn', 'user-group', 'menu.groups', '/groups', [PrivilegeType.VIEW_GROUPS]),
      new NavigationItem('users-btn', 'user-tie', 'menu.users', '/users', [PrivilegeType.VIEW_ORGANISATION_MEMBERS]),
    ],
  },
  serverSideDateFormat: 'yyyy-MM-dd',
  dateFormat: 'dd-MM-yyyy',
  dateTimeFormat: 'dd-MM-yyyy HH:mm',
  fullDateTimeFormat: 'dd-MM-yyyy HH:mm:ss',
  sidenav: {
    default: {
      toggleStatus: {
        desktop: true,
        mobile: false,
      },
    },
  },
  additionalToastMessage: {
    timeout: 4000,
  },
};

export interface DialogStyle {
  width: string;
  hasBackdrop: boolean;
}
