import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthorizationLoginComponent} from './components/authorization-login/authorization-login.component';
import {AuthHttpInterceptor, AuthModule} from '@auth0/auth0-angular';
import {environment} from 'src/environments/environment';
import { HTTP_INTERCEPTORS } from '@angular/common/http';


@NgModule({
  declarations: [
    AuthorizationLoginComponent
  ],
  imports: [
    CommonModule,
    AuthModule.forRoot({
      ...environment.auth0.config,
      httpInterceptor: {
        allowedList: [
          environment.lms.baseUrl + '*',
          environment.osm.baseUrl + '*',
          environment.cspBackend.baseUrl + '*',
          environment.apd.baseUrl + '*',
          environment.ess.baseUrl + '*',
          environment.ups.baseUrl + '*',
        ],
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
  exports: [
    AuthorizationLoginComponent
  ]
})
export class AuthorizationModule {
}
